<app-menu-bar></app-menu-bar>
<div class="regras-container">
  <div style="text-align: center">Definição de Regras de Alerta</div>
  <p-toolbar>
    <ng-template pTemplate="right">
      <div class="card flex justify-content-center">
        <p-toast></p-toast>
      </div>
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        label="Adicionar"
        class="p-button-info mr-2"
        (click)="add()"
      ></button>
    </ng-template>
  </p-toolbar>

  <div class="table-container">
    <p-table
      [value]="regras"
      [paginator]="true"
      [rows]="15"
      styleClass="p-datatable-gridlines"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
      [globalFilterFields]="[
        'dados.solucao',
        'dados.dtBase',
        'dados.condicao',
        'dados.dtAuxiliar',
        'dados.valor',
        'dados.status',
        'dados.frequencia',
        'dados.quantidade',
        'dados.notificaAavalara']">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="solucao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Solução
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="solucao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="solucao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dtbase">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Data Base
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dtbase"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dtbase"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="condicao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Condição
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="condicao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="condicao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dtAuxiliar">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Data Auxiliar
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dtAuxiliar"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dtAuxiliar"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="valor">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Valor
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="valor"></p-sortIcon>
                <p-columnFilter
                  type="number"
                  field="valor"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="statusDescricao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="statusDescricao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="statusDescricao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="frequencia">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Frequência
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="frequencia"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="frequencia"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="quantidade">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Quantidade de Alertas<BR>
                  (-1 - ilimitado)
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="quantidade"></p-sortIcon>
                <p-columnFilter
                  type="number"
                  field="quantidade"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="pesquisa">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Pesquisa de Satisfação
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="pesquisa"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="pesquisa"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"/>
              </div>
            </div>
          </th>
          <th pSortableColumn="notificaAvalara">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Notifica Avalara
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="notificaAvalara"></p-sortIcon>
                <p-columnFilter type="text" field="notificaAvalara" display="input" matchMode="contains" [showMatchModes]="true"
                  [showOperator]="true" [showAddButton]="true" />
              </div>
            </div>
          </th>

          <th style="text-align: center">Operação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dados>
        <tr>
          <td style="text-align: center">{{ dados.solucao }}</td>
          <td style="text-align: center">{{ dados.dtbaseDescricao }}</td>
          <td style="text-align: center">{{ dados.condicaoDescricao }}</td>
          <td style="text-align: center">{{ dados.dtauxiliarDescricao }}</td>
          <td style="text-align: center">{{ dados.valor }}</td>
          <td style="text-align: center">{{ dados.statusDescricao }}</td>
          <td style="text-align: center">{{ dados.frequenciaDescricao }}</td>
          <td style="text-align: center">{{ dados.qtdalertas }}</td>
          <td style="text-align: center">{{ dados.pesquisa }}</td>
          <td style="text-align: center">{{ dados.notificaAvalara }}</td>
          <td style="text-align: center">
            <p-button
              icon="pi pi-file-edit"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Editar"
              (click)="addEdit(dados)">
            </p-button>
            <p-button
              icon="pi pi-trash"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Apagar"
              (click)="deletarRegra(dados)">
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-confirmDialog
      appendTo="body"
      icon="pi pi-exclamation-triangle"
      [position]="'top'"
      [baseZIndex]="10000">
    </p-confirmDialog>

    <p-dialog
      header="Cadastro de Regras"
      [(visible)]="visible"
      [style]="{ width: '1000px', height: '750px' }"
      [modal]="true"
      (onHide)="hideDialog()"
      styleClass="p-fluid">
      <ng-template pTemplate="header">
        <h2>Manter Regra</h2>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="grid">
          <div class="p-field col-6 p-fluid">
            <label>Solução: *</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma solução"
              [options]="solucoes"
              (onChange)="solucaoSelecionado($event)"
              [(ngModel)]="regra.solucao"
              [required]="true"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
            <small class="p-error" *ngIf="submittedSolucao && !regra.solucao">Solução é obrigatória.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Status: *</label>
            <p-dropdown
              [options]="status"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="regra.status"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
            <small class="p-error"*ngIf="submittedStatus && !regra.status">Status é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Data Base: *</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma Data Base"
              [options]="dtbase"
              optionValue="valor"
              optionLabel="chave"
              (onChange)="dataBaseSelecionado($event)"
              [(ngModel)]="regra.dtbase"
              [required]="true"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
            <small class="p-error" *ngIf="submittedDtBase && !regra.dtbase">Data Base é obrigatória.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Condicao: *</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma Condicao"
              [options]="condicoes"
              optionValue="valor"
              optionLabel="chave"
              (onChange)="condicaoSelecionado($event)"
              [(ngModel)]="regra.condicao"
              [required]="true"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
            <small class="p-error" *ngIf="submittedCondicao && !regra.condicao">Condicao é obrigatória.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Data Auxiliar:</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma Data Auxiliar"
              [options]="dtbase"
              optionValue="valor"
              optionLabel="chave"
              (onChange)="dataAuxiliarSelecionado($event)"
              [(ngModel)]="regra.dtauxiliar"
              [required]="false"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Valor:</label>
            <input
              type="number"
              autocomplete="off"
              pInputText
              id="regra.valor"
              [(ngModel)]="regra.valor"/>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Frequencia: *</label>
            <p-dropdown
              [options]="frequencias"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="regra.frequencia"
              pTooltip="Campo Obrigatório">
            </p-dropdown>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Quantidade de Alertas:</label>
            <input
              type="number"
              autocomplete="off"
              pInputText
              id="regra.qtdalertas"
              [(ngModel)]="regra.qtdalertas"/>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Pesquisa Satisfação:</label>
            <p-dropdown
              [options]="pesquisas"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="regra.pesquisa">
            </p-dropdown>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Notifica Avalara</label>
            <p-dropdown [options]="notificacoesAvalara" optionValue="valor" optionLabel="chave" [(ngModel)]="regra.notificaAvalara"/>    
          </div>

          <div class="textarea-container">
            <div><label>Informativo:</label></div>
            <textarea
              rows="5"
              cols="120"
              [(ngModel)]="regra.informativo"
              class="custom-textarea">
            </textarea>
          </div>

        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button
          pButton
          label="Salvar"
          icon="pi pi-check"
          class="b-salvar"
          (click)="salvarRegra()">
        </button>
        <p-button
          pRipple
          label="Cancelar"
          icon="pi pi-times"
          class="b-cancelar"
          styleClass="p-button-secondary"
          (click)="hideDialog()">
        </p-button>
      </ng-template>
    </p-dialog>

  </div>
</div>
